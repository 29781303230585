export default {
    data() {
      return {
          isMobile: null,
      }
    },
    methods: {
        onWindowResize() {
            this.isMobile = window.innerWidth <= 768;
        },
    },
    mounted() {
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onWindowResize );
    }
}
