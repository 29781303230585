<template>
  <div class="slider" :id="id">
    <template v-if="slidesCount > 1">
      <span @click="prevSlide()" class="nav-prev"><i class="fas fa-chevron-left"></i></span>
      <span @click="nextSlide()" class="nav-next"><i class="fas fa-chevron-right"></i></span>
    </template>
    <div class="wrapper" :style="wrapperStyleObj">
      <div class="element" :style="elementStyleObj">
        <img src="/assets/images/header_1.jpg">
        <span class="text-box left">А ты точно подписан на KeynTR?</span>
      </div>
      <div class="element" :style="elementStyleObj">
        <video src="/assets/video/HoM&M_V.mp4" autoplay loop muted></video>
        <span class="text-box left">Увлекательные прохождения на YouTube канале</span>
      </div>
      <div class="element" :style="elementStyleObj">
        <video src="/assets/video/EldenRing.mp4" autoplay loop muted></video>
        <span class="text-box left">Стримы каждые выходные на Twitch</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThunderSlider',
  data() {
    return {
      mainElement: null,
      slideElement: null,
      slidesCount: null,
      wrapperStyleObj: {},
      elementStyleObj: {},
      currentSlide: 1,
      elementWidth: 0,
      autoLoopDirection: 'right',
    }
  },
  props: {
    id: {
      type: String,
      default: 'slider_1',
    },
    slidesPerPage: {
      type: Number,
      default: 1,
    },
    returnToFirst: {
      type: Boolean,
      default: false,
    },
    autoLoop: {
      type: Object,
      default: null,
    },
    sliderHeight: {
      type: String,
      default: '500px'
    }
  },
  mounted() {
    this.calcSize();

    if (this.autoLoop)
      this.autoLoopInit();
  },
  methods: {
    calcSize() {
      this.mainElement = document.getElementById(this.id);
      this.slideElement = this.mainElement.querySelectorAll('.wrapper>.element');

      if (this.slideElement.length > 0) {
        this.elementWidth = this.mainElement.offsetWidth;

        this.slidesCount = this.slideElement.length;

        let wrapperWidth = this.elementWidth * this.slidesCount;

        this.wrapperStyleObj.width = `${wrapperWidth}px`;
        this.wrapperStyleObj.height = `${this.sliderHeight}`;

        this.elementStyleObj.width = `${this.elementWidth}px`;
      }
    },

    toSlide(slideNumber) {

      /*
       * Переход к слайду по его порядковому номеру
       *
       * @slideNumber Number номер слайда 1 слайд 0
       */

      this.currentSlide = Number(slideNumber);

      if (this.canChangeSlide(slideNumber))
        this.wrapperStyleObj.marginLeft = -this.elementWidth * (slideNumber - 1) + 'px';
    },

    nextSlide() {

      /*
       * Функция переводит слайдер на 1 слайд вперёд
       */

      let slideNumber = this.currentSlide + 1

      if (this.canChangeSlide(slideNumber))
        this.toSlide(slideNumber);
      else if(this.returnToFirst)
        this.toSlide(1);
    },

    prevSlide() {

      /*
       * Функция переводит слайдер на 1 слайд назад
       */

      let slideNumber = this.currentSlide - 1

      if (this.canChangeSlide(slideNumber))
        this.toSlide(slideNumber);
      else if(this.returnToFirst)
        this.toSlide(this.slidesCount - this.slidesPerPage + 1);
    },

    canChangeSlide(slideNumber) {
      return slideNumber > 0 && slideNumber <= this.slidesCount;
    },

    autoLoopInit() {
      setInterval(() => {
        switch (this.autoLoopDirection) {
          case 'right':
            if (this.currentSlide + 1 === this.slidesCount)
              this.autoLoopDirection = 'left';

            this.toSlide(this.currentSlide + 1);
            break;

          case 'left':
            if (this.currentSlide - 1 === 1)
              this.autoLoopDirection = 'right';

            this.toSlide(this.currentSlide - 1);
            break;
        }
      }, this.autoLoop.delay);
    }
  }
}
</script>

<style scoped lang="scss">
.slider {
  max-height: 406px;

  margin: 0 -60px 1px -60px;

  position: relative;
  overflow: hidden;

  .nav-next,
  .nav-prev {
    position: absolute;
    top: calc(50% - 32px);
    height: 64px;
    font-size: 65px;
    color: #fff;
    z-index: 1;
    cursor: pointer;
    display: none;

    &:hover {
      color: var(--main-block-color);
    }
  }

  .nav-prev {
    left: 10px;
  }

  .nav-next {
    right: 10px;
  }

  //& .nav-prev:hover,
  //& .nav-next:hover {
  //  color: var(--main-color-1);
  //}

  &:hover .nav-prev,
  &:hover .nav-next {
    display: block;
  }

  span.text-box {
    position: absolute;
    bottom: 30px;
    background: var(--main-bg-color);
    font-size: 25px;
    text-transform: uppercase;

    &.right {
      right: 0;
      padding: 10px 15px 10px 50px;
    }

    &.left {
      left: 0;
      padding: 10px 50px 10px 15px;
    }
  }
}

.slider > .wrapper {
  display: inline-flex;
  transition: 1s;
  position: relative;

  .element {
    overflow: hidden;
    position: relative;

    img,
    video {
      width: 100%;
    }

    //video {
    //  max-height: 406px;
    //}
  }
}

@media (max-width: 768px) {
  .slider {
    display: none;
  }
}

</style>
