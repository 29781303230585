<template>
  <div>
    <ThunderSlider
        :autoLoop="{
          delay: 10000
        }"
        sliderHeight="406px"
    />

    <LastVideos/>

    <NewsList/>

<!--    <CurrentActual-->
<!--      online="true"-->
<!--    />-->
  </div>
</template>

<script>
import ThunderSlider from "@/components/slider/ThunderSlider";
import LastVideos from "@/components/youTube/LastVideos";
import NewsList from "@/components/news/NewsList";

export default {
  name: 'Index',
  components: {
    ThunderSlider,
    LastVideos,
    NewsList,
  },
}
</script>
