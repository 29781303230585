<template>
  <div v-for="article in getArticleList" :key="article.id">
    <NewsListElement
      :article=article
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import NewsListElement from "@/components/news/NewsListElement.vue";

export default {
  name: 'NewsList',
  components: {
    NewsListElement
  },
  created() {
    this.fetchArticleList();
  },
  computed: {
    ...mapGetters({
      getArticleList: 'Articles/getArticleList',
    }),
  },
  methods: {
    ...mapActions({
      fetchArticleList: 'Articles/fetchArticleList',
    }),
  }
}
</script>