<template>
  <div class="picture-block">
    <div class="solid-square" v-html="blockContent"></div>
    <div class="empty-square"></div>
  </div>
</template>

<script>
import mobileMixin from "@/mixins/mobileMixin";

export default {
  name: 'AnimatedSquadBlock',
  mixins: [ mobileMixin ],
  props: {
    type: {
      type: String,
      default: 'fasIcon',
      validator: function (value) {
        // Значение должно соответствовать одной из этих строк
        return ['fasIcon', 'image'].indexOf(value) !== -1
      }
    },
    faIconClass: {
      type: String,
      default: 'fa-user',
    },
    previewUrl: String,
    detailUrl: String,
  },
  data() {
    return {
      blockContent: null,
    }
  },
  watch: {
    isMobile() {
      this.setIBlockContent();
    }
  },
  created() {
    this.setIBlockContent();
  },
  methods: {
    setIBlockContent() {
      this.blockContent = '';

      if (this.type === 'fasIcon' && this.faIconClass) {
        this.blockContent += `<i class="fas ${this.faIconClass}"></i>`;
      }

      if (this.type === 'image' && this.previewUrl) {
        if (this.detailUrl)
          this.blockContent += `
          <a href="${this.detailUrl}" target="_blank">
        `;

        if (this.isMobile) {
          this.blockContent += `
        <div
            class="image-block"
            style="
                background: url(${this.previewUrl}) no-repeat;
                background-position: center;
                background-size: 100% auto ;
              "
        ></div>`;
        } else {
          this.blockContent += `
        <div
            class="image-block"
            style="
                background: url(${this.previewUrl}) no-repeat;
                background-position: center;
                background-size: auto 100%;
              "
        ></div>`;
        }

        if (this.detailUrl)
          this.blockContent += `
          </a>
        `;
      }
    }
  }
}
</script>

<style>
.picture-block {
  position: relative;
  width: 250px;
  height: 250px;
}

.picture-block {
  float: right;
  margin-left: 25px;
}

.picture-block .solid-square {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  height: 190px;
  width: 190px;

  background: var(--main-block-color);
  color: var(--second-text-color);

  text-align: center;
  font-size: 95px;
  line-height: 188px;
}

.picture-block .solid-square a {
  cursor: zoom-in;
}

.picture-block .solid-square .image-block {
  width: 100%;
  height: 100%;
  background-position: center;
}

.picture-block .empty-square {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;

  height: 150px;
  width: 150px;

  border: 20px solid var(--main-block-color);
}

.picture-block:hover .solid-square {
  top: 60px;
  left: 60px;
  transition: 1.2s;
}
</style>
