<template>
  <div class="lastVideosBlock">
    <div v-for="(video, index) in lastVideos" :key="index">
      <a :href="getYouTubeLink(video.video_id)" target="_blank">
        <img :src="video.thumbnails.medium.url"/>
      </a>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/axiosInstance";
import youTubeMixin from "@/mixins/youTubeMixin";

export default {
  name: 'LastVideos',

  mixins: [ youTubeMixin ],

  data() {
    return {
      lastVideos: null,
    }
  },

  created() {
    this.fetchLastVideos();
  },

  methods: {
    async fetchLastVideos() {
      try {
        await axiosInstance.post('youtube/lastVideo', { count: 4 }).then((response) => {
          if (response.data) {
            this.lastVideos = response.data;
            console.log(response.data);
          }
        });
      } catch (e) {
       console.log(e);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .lastVideosBlock {
    display: flex;
    margin: 0 -60px 0 -60px;

    div:nth-child(-n+3) {
      min-width: calc(25% - 1px);
    }

    div {
      min-width: 25%;
      margin-right: 1px;

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .lastVideosBlock {
      display: none;
    }
  }
</style>
