<template>
  <div class="article-box">
    <div class="article-header">
      <span class="header-line">
          <router-link :to=article.code class="title">{{ article.title }}</router-link><span class="publisher-time">{{ article.created_at }}</span>
      </span>
      <span v-if="article.tags" class="header-line add-info">Теги: {{ article.tags }}</span>
    </div>
    <div class="article-body">
      <AnimatedSquadBlock
        type="image"
        :previewUrl=article.image
        :detailUrl=article.image
        v-if="article.image"
      />
      <div v-html="article.text_preview"></div>
    </div>
    <div class="article-footer">
      <router-link :to=article.code><button class="btn-type-1">Читать полностью <i class="fas fa-arrow-circle-right"></i></button></router-link>
      <span class="add-info">Оставлено {{ article.comments_count }} комментариев</span>
<!--      <span class="add-info">Оставлено 5 комментариев | Поставлено 5 <a href="#"><i class="fas fa-heart with-hover-v-1"></i></a></span>-->
    </div>
  </div>
</template>

<script>
  import AnimatedSquadBlock from "@/components/layouts/AnimatedSquadBlock.vue";

  export default {
    name: 'NewsListElement',
    components: {
      AnimatedSquadBlock,
    },
    props: {
      article: {
        type: Object,
      },
    },
  }
</script>
